<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>商品管理</template>
      <template v-slot:secondMenu>提交评论</template>
    </breadcrumb-nav>
    <el-card class="box-card goods-add" shadow="never">
      <el-button type="text" icon="el-icon-back" @click="$router.back(-1)">返回列表</el-button>
      <el-divider direction="vertical"></el-divider>
      <el-button type="primary" @click="submitComment">提交评论</el-button>
    </el-card>
    <el-card>
      <el-form size="mini" :model="form" :rules="formRules" ref="formRef" label-width="100px"
               label-position="right">
        <el-row :gutter="5" style="margin-bottom: 10px">
          <el-col :span="8">
            <el-form-item label="昵称" prop="nick">
              <el-input style="width: 100%" v-model="form.nick"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="评论时间" prop="commentTime">
              <el-date-picker style="width:100%"
                              v-model="form.commentTime"
                              type="datetime"
                              align="right"
                              prop="commentTime"
                              value-format="timestamp"
                              placeholder="请选择评论时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="5" style="margin-bottom: 10px">
          <el-col :span="8">
            <el-form-item label="评分" prop="star">
              <el-rate style="display: inline-block;" v-model="form.star"></el-rate>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="5" style="margin-bottom: 10px">
          <el-col :span="8">
            <el-form-item label="评论头像" prop="avatar">
              <uploader ref="uploader" :file-list="fileList" :limit-count="limitCount"  @remove-success="handleRemove"
                        @upload-success="handleSuccess"></uploader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="评论内容" prop="content">
              <el-input v-model="form.content" type="textarea" placeholder="评论内容" rows="7"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="5" style="margin-bottom: 10px">
          <el-col :span="24">
            <el-form-item label="评论图片" prop="icons">
              <uploader ref="comment" :limit-count="maxLimit" :multiple=true></uploader>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../../common/BreadcrumbNav'
import Uploader from '../../common/Uploader'
import { manualGoodsComment } from '../../../network/goods'
export default {
  name: 'GoodsCommentSubmit',
  components: {
    BreadcrumbNav,
    Uploader
  },
  data () {
    return {
      form: {
        goodsId: 0,
        skuId: 0,
        nick: '匿名买家',
        commentTime: new Date().getTime(),
        content: '',
        star: 5,
        avatar: '530311963373569',
        icons: []
      },
      formRules: {
        nick: [
          {
            required: true,
            message: '请输入昵称',
            trigger: 'blur'
          }
        ],
        content: [
          {
            required: true,
            message: '请输入评论内容',
            trigger: 'blur'
          }
        ],
        avatar: [
          {
            required: true,
            message: '请选择头像',
            trigger: 'blur'
          }
        ]
      },
      fileList: [],
      limitCount: 1,
      maxLimit: 3
    }
  },
  mounted () {
    this.form.goodsId = parseInt(this.$route.query.goodsId)
    this.form.skuId = parseInt(this.$route.query.skuId)
    this.fileList.push({
      url: window.g.MEDIA_DOWNLOAD_URL + '530311963373569',
      mediaId: '530311963373569',
      remote: true
    })
  },
  methods: {
    handleRemove (files) {
      this.form.avatar = ''
    },
    handleSuccess (files) {
      this.form.avatar = files[0].mediaId
    },
    getCommentImages () {
      const images = []
      for (const item of this.$refs.comment.uploadFiles) {
        if (item && item.response && item.response.data && item.response.data.list && item.response.data.list.length > 0) {
          const list = item.response.data.list
          for (const image of list) {
            images.push(image.mediaId)
          }
        } else if (item.remote) {
          images.push(item.mediaId)
        }
      }
      if (images.length <= 0) {
        return ''
      }
      return images.toString()
    },
    submitComment () {
      this.form.icons = this.getCommentImages()
      this.$refs.formRef.validate(valid => {
        if (!valid) {
          return
        }
        manualGoodsComment(this.form).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('提交评论失败', 'error')
          }
          this.alertMessage('提交评论成功', 'success')
          this.$router.back()
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
